<template>
  <div>
    <v-window
      v-model="window"
      touchless
    >
      <v-window-item
        value="questions"
        class="text-center"
      >
        <div class="pa-6">
          <div>
            <div class="w-title mb-8">Which state do you reside in?</div>
            <div class="btn-options-grid">
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'act'),
                    (window = 'unsupported_state')
                "
                >ACT</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'nsw'), (window = 'create')
                "
                >NSW</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'nt'),
                    (window = 'unsupported_state')
                "
                >NT</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'qld'),
                    (window = 'unsupported_state')
                "
                >QLD</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'sa'),
                    (window = 'unsupported_state')
                "
                >SA</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'tas'),
                    (window = 'unsupported_state')
                "
                >TAS</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'vic'), (window = 'create')
                "
                >VIC</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'wa'),
                    (window = 'unsupported_state')
                "
                >WA</v-btn
              >
            </div>
          </div>
        </div>
      </v-window-item>

      <v-window-item value="create">
        <div class="pa-6">
          <div class="text-center auth-form-inner">
            <div class="w-title font-weight-bold font-cooper mb-8">
              Create account
            </div>
            <registration-form :from-poa="true" />
            <p class="mt-6">
              Already have an account?
              <a @click="$router.push('/login')">Log in</a>
            </p>
          </div>
        </div>
      </v-window-item>

      <v-window-item value="unsupported_state">
        <div class="pa-6">
          <div class="max-w-sm">
            <div class="error-image mb-8">
              <img src="@/assets/illustrations/sorry.svg" />
            </div>
            <p>
              Unfortunately we don't offer Power of Attorney documents in your
              state yet. We are working hard to make this available to you soon.
            </p>
            <p>In the meantime, you can still create your Will.</p>
            <v-btn
              v-bind="largeButton"
              @click="$router.push('/get-started')"
              class="mt-8 mx-auto d-block"
              >Create a Will</v-btn
            >
            <div class="text-center mt-8">
              <a
                class="sorry-back"
                @click="window = 'questions'"
                ><v-icon color="black">mdi-chevron-left</v-icon>Back</a
              >
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import RegistrationForm from './forms/Registration'
import RegistrationSlider from './slides/Slider'

export default {
  name: 'RegistrationPoa',
  components: {
    RegistrationForm,
    RegistrationSlider,
  },
  data: function () {
    return {
      window: 'questions',
      blueButton: {
        depressed: true,
        color: 'primary',
        class: 'text-capitalize display-1 px-7 black--text',
        height: 48,
      },
    }
  },
  methods: {
    setRegistrationData(key, value) {
      this.$store.commit('account/SET_REGISTRATION_DATA', {
        key: key,
        value: value,
      })
      this.$store.commit('anon/SET_ANON_DATA', {
        key: key,
        value: value,
      })
    },
  },
}
</script>

<style lang="scss">
.error-image {
  margin: auto;
  width: 290px;
  height: 212px;
  display: flex;
  align-items: flex-end;

  img {
    width: auto;
    height: 100%;
    display: block;
    margin: auto;
  }
}

.btn-options-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
}
.btn-options {
  display: flex;
  width: 338px;
  margin: 48px auto 0;
  justify-content: space-between;
  &.dense {
    margin: 16px auto 0;
  }
  &.wide {
    width: 520px;
  }
  .v-btn {
    width: 158px;
  }

  @media (max-width: 525px) {
    align-items: center;
    width: auto;
    max-width: 100%;
    padding: 0 16px;
    flex-direction: column;
    .v-btn {
      margin-bottom: 16px;
    }
  }
}

a.sorry-back {
  color: #000 !important;
  font-weight: 500;
  font-size: 24px;
  i.v-icon.v-icon {
    vertical-align: baseline;
  }
}

a i.v-icon.v-icon {
  vertical-align: baseline;
}
</style>

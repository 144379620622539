var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{staticClass:"text-center",attrs:{"value":"questions"}},[_c('div',{staticClass:"pa-6"},[_c('div',[_c('div',{staticClass:"w-title mb-8"},[_vm._v("Which state do you reside in?")]),_c('div',{staticClass:"btn-options-grid"},[_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'act'),
                  (_vm.window = 'unsupported_state')}}},'v-btn',_vm.blueButton,false),[_vm._v("ACT")]),_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'nsw'), (_vm.window = 'create')}}},'v-btn',_vm.blueButton,false),[_vm._v("NSW")]),_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'nt'),
                  (_vm.window = 'unsupported_state')}}},'v-btn',_vm.blueButton,false),[_vm._v("NT")]),_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'qld'),
                  (_vm.window = 'unsupported_state')}}},'v-btn',_vm.blueButton,false),[_vm._v("QLD")]),_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'sa'),
                  (_vm.window = 'unsupported_state')}}},'v-btn',_vm.blueButton,false),[_vm._v("SA")]),_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'tas'),
                  (_vm.window = 'unsupported_state')}}},'v-btn',_vm.blueButton,false),[_vm._v("TAS")]),_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'vic'), (_vm.window = 'create')}}},'v-btn',_vm.blueButton,false),[_vm._v("VIC")]),_c('v-btn',_vm._b({on:{"click":function($event){_vm.setRegistrationData('state', 'wa'),
                  (_vm.window = 'unsupported_state')}}},'v-btn',_vm.blueButton,false),[_vm._v("WA")])],1)])])]),_c('v-window-item',{attrs:{"value":"create"}},[_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"text-center auth-form-inner"},[_c('div',{staticClass:"w-title font-weight-bold font-cooper mb-8"},[_vm._v(" Create account ")]),_c('registration-form',{attrs:{"from-poa":true}}),_c('p',{staticClass:"mt-6"},[_vm._v(" Already have an account? "),_c('a',{on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Log in")])])],1)])]),_c('v-window-item',{attrs:{"value":"unsupported_state"}},[_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"max-w-sm"},[_c('div',{staticClass:"error-image mb-8"},[_c('img',{attrs:{"src":require("@/assets/illustrations/sorry.svg")}})]),_c('p',[_vm._v(" Unfortunately we don't offer Power of Attorney documents in your state yet. We are working hard to make this available to you soon. ")]),_c('p',[_vm._v("In the meantime, you can still create your Will.")]),_c('v-btn',_vm._b({staticClass:"mt-8 mx-auto d-block",on:{"click":function($event){return _vm.$router.push('/get-started')}}},'v-btn',_vm.largeButton,false),[_vm._v("Create a Will")]),_c('div',{staticClass:"text-center mt-8"},[_c('a',{staticClass:"sorry-back",on:{"click":function($event){_vm.window = 'questions'}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-chevron-left")]),_vm._v("Back")],1)])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }